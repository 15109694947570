<template>
  <div id="header-one">
    
    <section
      class="bg-half-170 bg-light d-table w-100"
      style="background: url('images/real/build.png') bottom no-repeat"
    >
      <div class="container">
        <div class="row align-items-center mt-5">
          <div class="col-lg-7 col-md-6">
            <div class="title-heading">
              <span class="badge badge-pill badge-soft-primary"
                >Bienvenido</span
              >
              <SmartLabel
                tag="h4"
                className="heading my-3"
                target="title"
                v-model="content.title"
              />
              <SmartLabel
                tag="p"
                className="para-desc text-muted"
                target="subtitle"
                v-model="content.subtitle"
              />
              <!-- <div class="mt-4 pt-2">
                <a href="javascript:void(0)" class="btn btn-primary m-1"
                  >Ver Planes</a
                >
              </div> -->
            </div>
          </div>
          <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="card shadow rounded border-0 ml-lg-4">
              <div class="card-body">
                <h5 class="card-title text-center">
                  Para empezar a cotizar, comparte el código postal de tu vivienda
                </h5>
                <form class="login-form mt-4">
                  <div class="row">

                        <div class="col-md-12">
                      <div class="form-group">
                        <label
                          >Código postal<span class="text-danger"
                            > *</span
                          ></label
                        >
                        <div class="position-relative">
                          <map-pin-icon class="fea icon-sm icons"></map-pin-icon >
                          <input
                            v-model="$v.form.postalCode.$model"
                            type="number"
                            class="form-control pl-5"
                            placeholder="Código postal"
                            name="postalCode"
                            maxlength="5"
                            max="999999"
                            min="000001" 
                            minlength="5"
                          />
                          <div
                            class="invalid-feedback"
                            v-bind:class="{
                              'd-block': $v.form.postalCode.$error,
                            }"
                          >
                            <span>Proporcione un valor válido</span>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          >Tipo de plan
                          <span class="text-danger"> *</span></label
                        >
                        <div class="position-relative">
                          <b-form-select
                            v-model="$v.form.selectedPackage.$model"
                            placehoder="Seleccione tipo de seguro"
                            :options="options"
                          />
                          <div
                            class="invalid-feedback"
                            v-bind:class="{
                              'd-block': $v.form.selectedPackage.$error,
                            }"
                          >
                            <span>Seleccione un tipo de plan</span>
                          </div>
                        </div>
                      </div>
                    </div>
                
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="$v.form.agree.$model"
                            type="checkbox"
                            class="custom-control-input"
                            id="agree"
                          />

                          <label class="custom-control-label" for="agree"
                            >Acepto
                            <a @click="form.agree = true" target="_blank" href="https://www.aig.com.mx/content/dam/aig/lac/mexico/documents/forms/aig_aviso_de_privacidad_febrero_2020.pdf" class="text-primary"
                              >políticas de privacidad</a
                            ></label
                          >
                          <div
                            class="invalid-feedback"
                            v-bind:class="{
                              'd-block': $v.form.agree.$error,
                            }"
                          >
                            <span>Debes aceptas las políticas de privacidad</span>
                          </div>
                        </div>
                          <div class="custom-control custom-checkbox">
                          <input
                            v-model="$v.form.agree2.$model"
                            type="checkbox"
                            class="custom-control-input"
                            id="agree2"
                            @click="showEligibity = !$v.form.agree2.$model"
                          />

                          <label class="custom-control-label" for="agree2"
                            >Por favor verifique si su vivienda es asegurable</label>
                          <div
                            class="invalid-feedback"
                            v-bind:class="{
                              'd-block': $v.form.agree2.$error,
                            }"
                          >
                            <span>Debes aceptar que verificó si su vivienda es asegurable</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-center col-md-12">
                      <a-button
                        @click="nextStep()"
                        class="btn btn-md bg-primary btn-primary col-md-12"
                        :loading="loading"
                        >Obtener cotización
                      </a-button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  <a-modal v-model="showEligibity" title="Caracteristicas de viviendas asegurables" on-ok="handleOk">

     <template slot="footer">
        <a-button key="back" @click="handleCancel">
          Cerrar
        </a-button>
        <a-button key="submit" type="primary"  @click="handleOk">
          Aceptar
        </a-button>
      </template>


      <p>Este seguro únicamente cubre viviendas que cumplan con todas las características que se indican a continuación:</p>

      <ul>
        <li>Vivienda principal del Asegurado; es decir no cubre viviendas desocupadas.</li>
        <li>Construcción 100% maciza; es decir, construcción de muros de ladrillo, tabique o block, techos de concreto o bovedilla.</li>
        <li>El tipo de uso únicamente habitacional.</li>
        <li>Se amparan únicamente viviendas con más de 5km de distancia a cuerpos de agua.</li>
        <li>Debe contar con las medidas básicas de seguridad, como chapas en puertas y protecciones en ventanas o cualquier otro tipo de protecciones que salvaguarde los contenidos.</li>
        <li>Viviendas con antigüedad de hasta 33 años de construcción.</li>
      </ul>


      
   
    </a-modal>


  </div>
</template>

<script>
import { UserIcon, MailIcon, MapPinIcon } from "vue-feather-icons";
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";

import { zoneCosts } from "@/services/costco.service";

const isTrue = (value) => value === true;

export default {
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      showEligibity: false,
      options: [
        { value: null, text: 'Favor seleccione una opción' },
        { value: "PROPIETARIO", text: "Soy dueño de la vivienda" },
        { value: "ARRENDADOR", text: "Soy dueño y rento" },
        { value: "ARRENDATARIO", text: "Estoy rentando la vivienda" },
      ],
      form: {
        postalCode: "",
        selectedPackage: null,
        agree: false,
        agree2: false
      },
    };
  },

  validations: {
    form: {
      postalCode: { required, numeric },
      selectedPackage: { required },
      agree: { isTrue },
      agree2: { isTrue },
    },
  },

  name: "HeaderOne",
  components: {
    UserIcon,
    MailIcon,
    MapPinIcon,
    MapPinIcon
  },

  methods: {

    handleCancel(){
      this.showEligibity = false
    },
    handleOk(){
      this.form.agree2 = true;
      this.showEligibity = false
    },

    
    async nextStep() {
  
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      try {

        this.loading = true

        const data = await zoneCosts(this.form.postalCode)
        if(!data.status){
          

            this.loading = false


            this.$gtag.event('error_zone', {
            'event_category': 'quote',
            'event_label': 'Error caculate zone',
            'value': 1
          })


          return this.$swal.fire({
          title: `${data.message}`,
          timerProgressBar: false
        })

            // return this.$swal.fire("Oops!",
            //   "Código no valido o no existente en nuestros sistema",
            //   "error");


        }

        // console.log(data.zones);

        localStorage.setItem('zones', JSON.stringify(data.data))

        // (planId, secureAmount, zona_hidro, zona_sismica)

        //  const {data} = await getPlans(planId, secureAmount, zona_hidro, zona_sismica)



        // console.log(data.zones);

        this.loading = false


        // https://us-central1-dtc-2012.cloudfunctions.net/AIG_D2C_COSTCO_GET_ZONE?zipCode=32000


         this.$gtag.event('start_quote', {
            'event_category': 'quote',
            'event_label': 'Start quote',
            'value': 1
          })

          
         this.$router.push({
            name: "Insured",
            query: {
              postalCode: this.form.postalCode,
              selectedPackage: this.form.selectedPackage,
            },
          });
        
      } catch (error) {
        this.loading = false
      }


      


     
    },
  },
};
</script>